@if(!actualUrl.includes('/social')){
<!-- Banner Top -->
<section *ngIf="banners?.length > 0">
  <div class="flex justify-center">
    <ng-container *ngFor="let banner of banners;">
      <ng-container *ngIf="banner.core_visual_banner_area == 'full_banner_top_head'">
        <button (click)="banners_fnc_cta(banner)">
          <img loading="lazy" class="hidden sm:flex w-full sm:justify-center object-fill" src="{{banner.url_image}}" alt="{{banner.name}}" />
          <ng-container *ngIf="banner.url_image_mobile !== ''">
            <img loading="lazy" class="sm:hidden" src="{{banner.url_image_mobile}}" alt="{{banner.name}}" />
          </ng-container>
        </button>
      </ng-container>
    </ng-container>
  </div>
</section>
<header>
  <nav id="mobile_bar" class="bg-gray-50 lg:bg-transparent z-20 mx-auto block lg:flex max-w-7xl items-center justify-between lg:flex-col lg:items-start lg:justify-start lg:gap-x-8 space-y-5 lg:space-y-0 lg:gap-y-6 p-4 lg:p-0 lg:py-6 lg:px-8" aria-label="Global">
    <!-- First Section -->
    <section class="hidden w-full lg:block">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-x-4">
          <a href="https://www.facebook.com/casadege.com.br" target="_blank">
            <img loading="lazy" class="w-6 opacity-40 hover:opacity-20" src="/assets/images/redes_sociais/facebook.webp" alt="Facebook" />
          </a>
          <a href="https://instagram.com/casadege" target="_blank">
            <img loading="lazy" class="w-6 opacity-40 hover:opacity-20" src="/assets/images/redes_sociais/instagram.webp" alt="Instagram" />
          </a>
          <a href="https://youtube.com.br/channel/UC9sKpdku8Hy7t2Cru0vuaMw" target="_blank">
            <img loading="lazy" class="w-6 opacity-40 hover:opacity-20" src="/assets/images/redes_sociais/youtube.webp" alt="Youtube" />
          </a>
        </div>
        <div class="flex items-center gap-x-8 text-gray-700">
          <div>
            <a href="https://wa.me/+5511966294417" class="flex items-center gap-x-2" target="_blank">
              <img loading="lazy" src="/assets/images/whatsapp.webp" alt="WhatsApp" class="hover:opacity-20" />
              <p class="text-sm tracking-wide font-semibold">(11) 96629-4417</p>
            </a>
          </div>
          <div class="text-sm tracking-wide font-medium">Compra 100% segura</div>
          <div class="flex items-center gap-x-4">
            <ng-container *ngIf="!profile?.id">
              <div>
                <a [routerLink]="['/b']" class="text-sm leading-3"><strong>Entre</strong> ou <strong>Cadastre-se</strong></a>
              </div>
            </ng-container>
            <ng-container *ngIf="profile?.id">
              <div class="flex justify-center items-center space-x-1 group">
                <p [routerLink]="['/a']" class="text-sm inline-block align-middle">Olá, <strong>{{profile.name}} </strong></p>

                <div (click)="op.toggle($event)" class="cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-4 h-4 stroke-amber-400">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </div>
                <p-overlayPanel #op [style]="{width: '200px'}">
                  <div class="flex flex-col space-y-2">
                    <a [routerLink]="['/a/order']" class="text-pink-800 text-sm hover:font-semibold">Minhas Compras</a>
                    <a [routerLink]="['/a/wishlist']" class="text-pink-800 text-sm hover:font-semibold">Lista de Desejos</a>
                    <a [routerLink]="['/a']" class="text-pink-800 text-sm hover:font-semibold">Minha Conta</a>
                    <hr />
                    <a (click)="collectiveCustomerUserServiceLogout();" class="text-pink-800 text-sm hover:font-semibold cursor-pointer">Sair</a>
                  </div>
                </p-overlayPanel>
              </div>
            </ng-container>

            <ng-container *ngIf="cart?.client_marketplace_cart_uid">
              <a [routerLink]="['/p/cart/'+cart.client_marketplace_cart_uid]" class="relative inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 stroke-pink-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                <ng-container *ngIf="cart?.counter_item > 0">
                  <div class="absolute -top-2 left-6 inline-flex h-4 w-4 items-center justify-center rounded-full bg-pink-800 p-2.5 text-xs font-bold text-white">{{cart.counter_item}}</div>
                </ng-container>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngIf="cart?.delivery_info?.delivery_free == 'no' && cart?.delivery_info?.diff_amount_delivery_free > 0">
        <ng-container *ngIf="cart?.counter_item > 0">
          <div class="flex justify-end pt-2">
            <span [routerLink]="['/p/cart/'+cart.client_marketplace_cart_uid]" class="inline-flex items-center gap-1 rounded-full bg-blue-50 px-3 py-1 text-sm font-semibold text-blue-800 cursor-pointer hover:bg-blue-100"> Vamos de Frete Grátis? </span>
          </div>
        </ng-container>
      </ng-container>
    </section>

    <!-- Middle Section -->
    <section class="flex flex-col gap-y-4 items-center lg:flex-row lg:gap-x-6 w-full" [ngClass]="{'items-start': search.query !== ''}">
      <a id="logo" [routerLink]="['/']" title="{{marketplace?.name}}" class="flex justify-center">
        <div class="overflow-hidden cursor-pointer">
          <img class="w-36 sm:w-48 hover:opacity-75 mx-auto" src="/assets/themes/{{environment.marketplace.domainObject.sld}}/images/logo.png" alt="Logo {{marketplace?.name}}" />
        </div>
      </a>
      <div class="flex items-start gap-x-2 justify-between w-full">
        <button (click)="onHideMobileMenu()" type="button" class="text-pink-800 lg:hidden mt-2">
          <span class="sr-only">Open main menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>

        <div class="flex items-start gap-x-4 w-full">
          <div class="flex flex-col w-full gap-y-4 sm:min-w-[400px] md:min-w-[500px] lg:min-w-none relative">
            <form method="POST" (ngSubmit)="clientMarketplaceCoreProductServiceRedirectSearch();" class="relative w-full">
              <input type="search" [(ngModel)]="search.query" #search.query="ngModel" (keyup)="clientMarketplaceCoreProductServiceIndex()" class="z-20 block w-full border border-gray-300 bg-white p-2.5 text-sm text-gray-900 focus:border-blue-300 focus:border-transparent" placeholder="Buscar na Casa de Gê" />
              <div class="absolute end-2 top-1 border-l border-gray-200 p-4"></div>
              <button type="submit" class="absolute end-0 top-0 h-full p-3 text-sm font-medium text-gray-400 focus:border-blue-300 focus:border-transparent focus:outline-none">
                <svg class="h-4 w-4 bg-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
                <span class="sr-only">Buscar</span>
              </button>
            </form>

            <ng-container *ngIf="searchResults?.facetDistribution?.categories && search.query !== ''">
              <div id="list" class="flex items-center gap-2 flex-wrap w-full relative">
                <span class="hidden sm:block text-sm">Mais produtos:</span>
                <ng-container *ngFor="let category of searchResults?.facetDistribution?.categories; let index = index">
                  <ng-container *ngIf="index < 5">
                    <span class="inline-flex items-center truncate rounded-full bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-600/10">
                      <ng-container *ngIf="search.query !== defined && search.query != ''">
                        <a [routerLink]="['/s/query/'+search.query]" [queryParams]="{'category': category.index}"> {{category.index}} </a>
                      </ng-container>
                    </span>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="!actualUrl.match('/s(.*)')">
              <ng-container *ngIf="searchResults?.data && search.query && search.query != ''">
                <ul id="dropdown" class="absolute z-20 bg-white border border-gray-200 w-full mt-2 rounded-md shadow-md divide-y divide-gray-200">
                  <ng-container *ngFor="let result of searchResults?.data; let index = index;">
                    <ng-container *ngIf="index < 4">
                      <li class="p-4 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900">
                        <div class="flex items-center gap-x-3">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-gray-400">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                          </svg>

                          <div class="w-10 flex justify-center items-center">
                            <a [href]="result.url" class="cursor-pointer">
                              <img src="{{result.image_thumb}}" alt="{{result.name}}" class="w-10" />
                            </a>
                          </div>
                          <div role="button">
                            <a [href]="result.url" class="cursor-pointer space-y-2">
                              <span class="leading-[6px] text-sm" [innerHTML]="result._formatted.name | html"></span>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </ng-container>
            </ng-container>
          </div>

          <ng-container *ngIf="cart?.client_marketplace_cart_uid">
            <a [routerLink]="['/p/cart/'+cart.client_marketplace_cart_uid]" class="lg:hidden relative flex justify-end">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-7 w-7 mt-2 text-pink-800">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
              </svg>
              <ng-container *ngIf="cart.counter_item > 0">
                <div class="absolute -end-1 -top-0.5 inline-flex h-4 w-4 items-center justify-center rounded-none bg-gray-50 text-xs font-bold text-pink-800">{{cart.counter_item}}</div>
              </ng-container>
            </a>
          </ng-container>
        </div>
      </div>
    </section>
  </nav>
  <!-- Bottom Section -->
  <section id="desktop_bar" class="z-20">
    <div class="hidden lg:block bg-pink-800 sm:py-1 sm:mb-4">
      <div class="sm:px-8 sm:pb-2 md:pb-0 md:px-0">
        <div class="flex items-center justify-center space-x-8 sm:flex-wrap">
          <ul>
            <li class="group dropdown cursor-pointer">
              <div class="flex items-center space-x-1 p-3 group-hover:font-bold group-hover:bg-white group-hover:rounded-t-lg">
                <p class="text-center text-white text-sm tracking-wide uppercase font-semibold truncate group-hover:text-pink-800">
                  <a [routerLink]="['/s/query/Limpeza']" queryParamsHandling="merge"> Itens de Limpeza </a>
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-4 h-4 stroke-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </div>
              <div class="group-hover:block group-hover:ease-in-out group-hover:duration-500 dropdown-menu absolute hidden h-auto z-10">
                <ul class="top-0 w-fit bg-white shadow px-8 py-7 grid grid-cols-2 grid-rows-5">
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Detergente'}" queryParamsHandling="merge" class="text-sm text-gray-800 truncate hover:font-semibold">Detergente</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Lenço Seco Eletrostático'}" class="text-sm text-gray-800 truncate hover:font-semibold">Lenço Seco Eletrostático!</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Lenço Umedecido'}" class="text-sm text-gray-800 truncate hover:font-semibold">Lenço Umedecido</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'MOP'}" class="text-sm text-gray-800 truncate hover:font-semibold">MOP</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Mofo'}" class="text-sm text-gray-800 truncate hover:font-semibold">Tira Mofo</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Limpador'}" class="text-sm text-gray-800 truncate hover:font-semibold">Limpador</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Sabão em Pasta'}" class="text-sm text-gray-800 truncate hover:font-semibold">Sabão em pasta</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Limo'}" class="text-sm text-gray-800 truncate hover:font-semibold">Tira Limo</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Lava Louças'}" class="text-sm text-gray-800 truncate hover:font-semibold">Lava Louças</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Desinfetante'}" class="text-sm text-gray-800 truncate hover:font-semibold">Desinfetante</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Limpeza'}" class="text-sm text-gray-800 truncate hover:font-semibold">Limpeza</a></li>
                </ul>
              </div>
            </li>
          </ul>

          <ul>
            <li class="group dropdown cursor-pointer">
              <div class="flex items-center space-x-1 p-3 group-hover:font-bold group-hover:bg-white group-hover:rounded-t-lg">
                <p class="text-center text-white text-sm tracking-wide uppercase font-semibold truncate group-hover:text-pink-800">
                  <a [routerLink]="['/s/query/KIT']" queryParamsHandling="merge"> KITS </a>
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-4 h-4 stroke-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </div>
              <div class="group-hover:block group-hover:ease-in-out group-hover:duration-500 absolute hidden h-auto z-10">
                <ul class="top-0 w-fit bg-white shadow px-8 py-7 grid md:grid-cols-2 gap-x-6 sm:grid-cols-1">
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'KIT - Cuidados com as Roupas'}" class="text-sm text-gray-800 truncate hover:font-semibold">KIT - Cuidados com as Roupas</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'KIT - Cuidados com a Casa'}" class="text-sm text-gray-800 truncate hover:font-semibold">KIT - Cuidados com a Casa</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Kit Misto'}" class="text-sm text-gray-800 truncate hover:font-semibold">Kit Misto</a></li>
                </ul>
              </div>
            </li>
          </ul>

          <ul>
            <li class="group dropdown cursor-pointer">
              <div class="flex items-center space-x-1 p-3 group-hover:font-bold group-hover:bg-white group-hover:rounded-t-lg">
                <p class="text-center text-white text-sm tracking-wide uppercase font-semibold truncate group-hover:text-pink-800">
                  <a [routerLink]="['/s/query/Lavanderia']" queryParamsHandling="merge"> Lavanderia </a>
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-4 h-4 stroke-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </div>
              <div class="group-hover:block group-hover:ease-in-out group-hover:duration-500 absolute hidden h-auto z-10">
                <ul class="top-0 w-fit bg-white shadow px-8 py-7 grid grid-cols-2 gap-x-6">
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Manchas'}" class="text-sm text-gray-800 truncate hover:font-semibold">Tira Manchas</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Lavanderia'}" class="text-sm text-gray-800 truncate hover:font-semibold">Lavanderia</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Lava Roupas'}" class="text-sm text-gray-800 truncate hover:font-semibold">Lava Roupas</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Amaciantes'}" class="text-sm text-gray-800 truncate hover:font-semibold">Amaciantes</a></li>
                  <li class="py-1"><a [routerLink]="['/s']" [queryParams]="{'category': 'Facilitadores'}" class="text-sm text-gray-800 truncate hover:font-semibold">Facilitadores</a></li>
                </ul>
              </div>
            </li>
          </ul>

          <a [routerLink]="['/subscription']" class="p-3">
            <p class="text-center text-white text-sm tracking-wide uppercase font-semibold truncate cursor-pointer hover:font-bold">Clube Gê</p>
          </a>
          <div>
            <p class="text-center text-white text-sm tracking-wide uppercase font-semibold truncate cursor-pointer hover:font-bold">
              <a [routerLink]="['/salesman']" class="p-3"> Marcas </a>
            </p>
          </div>
          <div>
            <p class="text-center text-white text-sm tracking-wide uppercase font-semibold truncate cursor-pointer hover:font-bold">
              <a [routerLink]="['/s/sale/yes']" class="p-3">Promoções</a>
            </p>
          </div>
          <div>
            <p class="text-center text-white text-sm tracking-wide uppercase font-semibold truncate cursor-pointer hover:font-bold">
              <a [routerLink]="['/blog']" class="p-3">Blog</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Mobile menu, show/hide based on menu open state. -->
  <ng-container *ngIf="!hideMenu">
    <div class="lg:hidden ease-in duration-300" role="dialog" aria-modal="true">
      <div class="fixed inset-0 z-40 bg-black opacity-40"></div>
      <div class="fixed inset-y-0 left-0 z-50 w-96 divide-y divide-gray-200 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between pb-5">
          <div class="flex items-start gap-x-4" [ngClass]="{'items-center': !profile?.id }">
            <span class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
              <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
            <ng-container *ngIf="!profile?.id">
              <a [routerLink]="['/b']" class="font-medium text-gray-800"><strong>Entre</strong> ou <br /><strong>Cadastre-se</strong></a>
            </ng-container>
            <ng-container *ngIf="profile?.id">
              <a [routerLink]="['/a']">
                <div class="flex flex-col gap-y-1">
                  <p class="font-medium text-gray-800">{{profile?.name}}</p>

                  <div class="flex items-center gap-x-1 text-gray-500">
                    <p class="text-sm">Meu perfil</p>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-3 w-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>
                    </span>
                  </div>
                </div>
              </a>
            </ng-container>
          </div>
          <button (click)="onHideMobileMenu()" type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700">
            <span class="sr-only">Close menu</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <ul role="list" class="py-4 flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li>
                <a [routerLink]="['/']" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700" [ngClass]="{'text-pink-800': actualUrl == '/'}">
                  <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'text-pink-800': actualUrl == '/'}">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                  </svg>
                  Início
                </a>
              </li>
              <li>
                <a [routerLink]="['/s']" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700" [ngClass]="{'text-pink-800': actualUrl == '/s'}">
                  <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'text-pink-800': actualUrl == '/s'}">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                  </svg>
                  Buscar
                </a>
              </li>
              <li>
                <a [routerLink]="['/a/wishlist']" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700" [ngClass]="{'text-pink-800': actualUrl == '/a/wishlist'}">
                  <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'text-pink-800': actualUrl == '/a/wishlist'}">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                  </svg>

                  Lista de desejos
                </a>
              </li>
              <li>
                <a [routerLink]="['/a/order']" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700" [ngClass]="{'text-pink-800': actualUrl == '/a/order'}">
                  <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'text-pink-800': actualUrl == '/a/order'}">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                    />
                  </svg>
                  Minhas compras
                </a>
              </li>
              <li>
                <div>
                  <button type="button" class="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50" aria-controls="sub-menu-1" aria-expanded="false" [ngClass]="{'text-pink-800': actualUrl.includes('/s/query')}">
                    <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'text-pink-800': actualUrl.includes('/s/query')}">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
                    </svg>
                    Categorias
                  </button>
                  <!-- Expandable link section, show/hide based on state. -->
                  <ul class="mt-1 px-2" id="sub-menu-1">
                    <li class="flex flex-col">
                      <!-- 44px -->
                      <div class="flex items-center">
                        <a [routerLink]="['/s/query/Limpeza']" queryParamsHandling="merge" class="block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 hover:bg-gray-50 font-medium" [ngClass]="{'text-pink-900': actualUrl.includes('Limpeza')}">Itens de Limpeza</a>
                        <button (click)="onShowCategoryChildren('cleaning')" class="ml-auto">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-4 w-4 shrink-0 text-gray-400">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                          </svg>
                        </button>
                      </div>
                      <ng-container *ngIf="cleaning">
                        <ul class="block pl-12 pt-2 text-sm text-gray-600 pb-4">
                          <li class="grid grid-cols-2 gap-y-2">
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Detergente'}" queryParamsHandling="merge">Detergente</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Lenço Seco Eletrostático'}" queryParamsHandling="merge">Lenço Umedecido</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Mofo'}" queryParamsHandling="merge">Tira Mofo</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Sabão em Pasta'}" queryParamsHandling="merge">Sabão em pasta</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Lava Louças'}" queryParamsHandling="merge">Lava louças</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Limpeza'}" queryParamsHandling="merge">Limpeza</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'MOP'}" queryParamsHandling="merge">MOP</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Limpador'}" queryParamsHandling="merge">Limpador</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Limo'}" queryParamsHandling="merge">Tira limo</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Desinfetante'}" queryParamsHandling="merge">Desinferante</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Lenço Umedecido'}" queryParamsHandling="merge">Lenço Seco Eletrostático</a>
                          </li>
                        </ul>
                      </ng-container>
                    </li>
                    <li class="flex flex-col">
                      <!-- 44px -->
                      <div class="flex items-center">
                        <a [routerLink]="['/s/query/KIT']" queryParamsHandling="merge" class="block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 hover:bg-gray-50 font-medium" [ngClass]="{'text-pink-900': actualUrl.includes('KIT')}">Kits</a>
                        <button (click)="onShowCategoryChildren('kits')" class="ml-auto">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-4 w-4 shrink-0 text-gray-400">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                          </svg>
                        </button>
                      </div>
                      <ng-container *ngIf="kits">
                        <ul class="block pl-12 pt-2 text-sm text-gray-600">
                          <li class="grid grid-cols-2 gap-y-2">
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'KIT - Cuidados com as Roupas'}" queryParamsHandling="merge">Cuidados com as Roupas</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'KIT - Cuidados com a Casa'}" queryParamsHandling="merge">Cuidados com a Casa</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Kit Misto'}" queryParamsHandling="merge">Kit Misto</a>
                          </li>
                        </ul>
                      </ng-container>
                    </li>
                    <li class="flex flex-col">
                      <!-- 44px -->
                      <div class="flex items-center">
                        <a [routerLink]="['/s/query/Lavanderia']" queryParamsHandling="merge" class="block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 hover:bg-gray-50 font-medium" [ngClass]="{'text-pink-900': actualUrl.includes('Lavanderia')}">Lavanderia</a>
                        <button (click)="onShowCategoryChildren('laundry')" class="ml-auto">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-4 w-4 shrink-0 text-gray-400">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                          </svg>
                        </button>
                      </div>
                      <ng-container *ngIf="laundry">
                        <ul class="block pl-12 pt-2 text-sm text-gray-600">
                          <li class="grid grid-cols-2 gap-y-2">
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Manchas'}" queryParamsHandling="merge">Tira Manchas</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Lavanderia'}" queryParamsHandling="merge">Lavanderia</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Lava Roupas'}" queryParamsHandling="merge">Lava Roupas</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Amaciantes'}" queryParamsHandling="merge">Amaciantes</a>
                            <a [routerLink]="['/s']" [queryParams]="{'category': 'Facilitadores'}" queryParamsHandling="merge">Facilitadores</a>
                          </li>
                        </ul>
                      </ng-container>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a [routerLink]="['/subscription']" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50" [ngClass]="{'text-pink-800': actualUrl.includes('subscription')}">
                  <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'text-pink-800': actualUrl.includes('subscription')}">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                    />
                  </svg>
                  Clube Gê
                </a>
              </li>
              <li>
                <a [routerLink]="['/salesman']" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50" [ngClass]="{'text-pink-800': actualUrl.includes('salesman')}">
                  <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'text-pink-800': actualUrl.includes('salesman')}">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z" />
                  </svg>
                  Marcas
                </a>
              </li>
              <li>
                <a [routerLink]="['/s/sale/promo']" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50" [ngClass]="{'text-pink-800': actualUrl.includes('promo')}">
                  <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'text-pink-800': actualUrl.includes('promo')}">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                    />
                  </svg>
                  Promoções
                </a>
              </li>
              <li>
                <a [routerLink]="['/blog']" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50" [ngClass]="{'text-pink-800': actualUrl.includes('blog')}">
                  <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'text-pink-800': actualUrl.includes('blog')}">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>
                  Blog
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <ng-container *ngIf="profile?.id">
          <div class="pt-4 flex flex-1 flex-col gap-y-7">
            <ul role="list" class="-mx-2 space-y-1">
              <li>
                <button (click)="collectiveCustomerUserServiceLogout();" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700">
                  <svg class="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                  </svg>

                  Sair
                </button>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</header>
} @else {
<header class="py-8 bg-gray-50 px-8">
  <a id="logo" [routerLink]="['/']" title="{{marketplace?.name}}">
    <div class="overflow-hidden cursor-pointer">
      <img class="w-36 sm:w-48 hover:opacity-75" src="/assets/themes/casadege/images/logo.png" />
    </div>
  </a>
</header>
}
