<p-toast position="center"></p-toast>
@defer(on viewport){
<footer class="w-full">
  <div>
    @if(!actualUrl.includes('/social')){
    <ng-container *ngIf="!actualUrl.match('/p/(.*)/id') && !actualUrl.match('/p/cart') && !actualUrl.match('/p/checkout') && !actualUrl.match('/a/(.*)') && !actualUrl.match('/b/(.*)') && !actualUrl.match('/voucher/(.*)')">
      <div class="mx-auto max-w-7xl pb-6 flex justify-center">
        <section class="z-0 relative">
          <ng-container *ngIf="banners?.length > 0">
            <ng-container *ngIf="fullBannerFooterBanners?.length > 0">
              <p-carousel [value]="fullBannerFooterBanners" [numVisible]="1" [numScroll]="1" [circular]="true" autoplayInterval="5500" [responsiveOptions]="responsiveOptions">
                <ng-template let-banner pTemplate="item">
                  <img loading="lazy" class="hidden sm:block cursor-pointer" src="{{banner.url_image}}" alt="{{banner.name}}" (click)="banners_fnc_cta(banner)" />
                  <img loading="lazy" class="sm:hidden rounded-lg" src="{{banner.url_image_mobile}}" alt="{{banner.name}}" (click)="banners_fnc_cta(banner)" />
                </ng-template>
              </p-carousel>
            </ng-container>
          </ng-container>
        </section>
      </div>
    </ng-container>

    <div class="bg-gray-100 py-12">
      <div class="mx-auto max-w-7xl flex flex-col space-y-4 sm:flex-row justify-start sm:justify-between px-8">
        <div class="max-w-xl font-bold tracking-tight text-pink-800 text-3xl col-span-7">
          <h2 class="inline sm:block lg:inline xl:block">Receba Descontos, Lançamentos e Ofertas!</h2>
        </div>
        <form class="grid-cols-2" (ngSubmit)="collectiveLeadServiceStore();">
          <div class="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:gap-x-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
              </span>
              <input id="name" type="text" [(ngModel)]="lead.name" #lead.name="ngModel" pInputText placeholder="Seu Nome" class="w-full border border-gray-300 text-sm ring-pink-800 focus:ring-1 focus:ring-inset focus:ring-pink-900" />
            </div>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-at"></i>
              </span>
              <input id="email" type="text" [(ngModel)]="lead.email" #lead.email="ngModel" pInputText placeholder="Seu Email" class="w-full border border-gray-300 text-sm ring-pink-800 focus:ring-2 focus:ring-inset focus:ring-pink-900" />
            </div>
            <button type="submit" class="w-fit flex-none rounded-md bg-pink-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Eu quero!</button>
          </div>
        </form>
      </div>
    </div>
    }
    <!-- Compra Segura e Cartões -->
    <div class="flex flex-col justify-center items-center bg-pink-800 mx-auto sm:flex-row py-8 space-y-8 sm:space-y-0">
      <div class="flex items-center justify-center space-x-2 sm:flex-col sm:space-y-2 px-8">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="stroke-white w-8 h-8 sm:w-10 sm:h-10">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
        </svg>
        <p class="text-white font-semibold text-xs sm:text-lg">Compra 100% segura</p>
      </div>
      <div class="flex sm:justify-center flex-col sm:items-center sm:mt-2 space-y-3 px-8">
        <p class="text-white text-xs sm:text-sm text-center font-semibold">Formas de pagamento aceitas</p>
        <div><img class="w-96" src="/assets/images/Bandeiras-horizontal-grande-svg.png" alt="Formas de Pagamento Aceitas" /></div>
      </div>
    </div>

    <div [ngClass]="{'justify-start': actualUrl.includes('/social')}" class="flex flex-col sm:items-start bg-[#f5f5f5] sm:flex-row mx-auto justify-center">
      <!-- Todos os Departamentos -->
      @if(!actualUrl.includes('/social')){
      <div class="p-8">
        <p class="text-pink-800 text-sm font-semibold sm:text-base">
          <a [routerLink]="['/s']">Todos os departamentos</a>
        </p>
        <div class="sm:flex sm:flex-col sm:items-center">
          <div class="grid grid-cols-3 gap-x-6 sm:gap-x-8 overflow-auto mt-4 no-scrollbar sm:grid-cols-3 sm:grid-rows-none sm:gap-y-2 sm:overflow-hidden">
            <ng-container *ngFor="let category of categories?.data;">
              <a [routerLink]="['/s/category/'+category.name_handle]" class="text-gray-700 text-xs p-2 sm:text-sm hover:font-medium whitespace-pre-line">{{category.name}}</a>
            </ng-container>
          </div>
          <div class="mt-3 sm:flex sm:justify-center"><a [routerLink]="['/s']" class="text-xs p-2 text-gray-800 sm:text-sm font-semibold text-center">Ver todos</a></div>
        </div>
      </div>
      }

      <!-- Duvidas -->
      <div [ngClass]="{'grid grid-cols-3 gap-x-8 space-y-0 justify-start': actualUrl.includes('/social')}" class="p-8 flex flex-col space-y-6 justify-center md:mt-4">
        <div>
          <p class="text-pink-800 text-sm font-semibold sm:text-base">Dúvidas?</p>
          <div class="sm:flex sm:flex-col sm:space-x-0 mt-4 space-y-4">
            <p class="text-gray-700 text-xs underline block">(11) 96629-4417</p>
            <p class="text-gray-700 text-xs underline block">{{marketplace?.email}}</p>
          </div>
        </div>

        <div>
          <p class="text-pink-800 text-sm font-semibold sm:text-base">Políticas</p>
          <div class="mt-4 space-y-2">
            <a [routerLink]="['/article/politica-de-privacidade']" class="text-gray-700 text-xs underline block">Privacidade</a>
            <a [routerLink]="['/article/politica-de-troca']" class="text-gray-700 text-xs underline block">Trocas</a>
            <a [routerLink]="['/article/politica-de-pagamento']" class="text-gray-700 text-xs underline block">Pagamento</a>
            <a [routerLink]="['/article/politica-de-desconto']" class="text-gray-700 text-xs underline block">Desconto</a>
            <a [routerLink]="['/article/termos-e-condicoes']" class="text-gray-700 text-xs underline block">Termos e Condições</a>
          </div>
        </div>

        <div>
          <p class="text-pink-800 text-sm font-semibold sm:text-base">Empresa</p>
          <div class="mt-4 space-y-2">
            <a [routerLink]="['/page/nos']" class="text-gray-700 text-xs underline block">Nós</a>
          </div>
        </div>
      </div>

      <!-- <div class="max-w-xs py-8">
        <div class="z-0 sm:flex sm:justify-center sm:m-0">
          <div class="mt-4 border border-gray-200 rounded-lg p-6 shadow-lg shadow-orange-200/50 sm:p-4 sm:mt-0 sm:my-8">
            <p class="text-pink-800 text-center font-bold leading-4 sm:text-xl sm:mb-3">Receba Descontos, Lançamentos e Ofertas.</p>
            <form (ngSubmit)="collectiveLeadServiceStore();">
              <div class="flex flex-col space-y-2 py-3 sm:space-y-4">
                <div class="flex items-center space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="text-pink-700 pl-2 w-6 h-6 left-3 sm:w-8 sm:h-8" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  <input type="text" name="name" [(ngModel)]="lead.name" #lead.name="ngModel" id="name" placeholder="Seu Nome" class="bg-gray-100 text-xs border-transparent rounded-full py-2 px-4 placeholder-gray-400 text-gray-500 appearance-none w-full block sm:text-sm" />
                </div>
                <div class="flex items-center space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="text-pink-700 pl-2 w-6 h-6 left-3 sm:w-8 sm:h-8" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                  <input type="email" name="email" [(ngModel)]="lead.email" #lead.email="ngModel" placeholder="Seu Email" class="bg-gray-100 text-xs border-transparent rounded-full py-2 px-4 placeholder-gray-400 text-gray-500 appearance-none w-full block sm:text-sm" />
                </div>
              </div>
              <div class="flex justify-center items-center">
                <button type="submit" class="rounded-full w-20 text-white p-2 text-center text-xs sm:text-sm sm:w-32 bg-pink-800 hover:bg-pink-900">Eu quero!</button>
              </div>
            </form>
          </div>
        </div>
      </div> -->
    </div>

    <!-- Informações -->
    <div [ngClass]="{'px-0 pb-8': actualUrl.includes('/social')}" class="mt-8 px-4 md:flex md:items-center md:justify-between mx-auto max-w-5xl sm:mt-12 z-10">
      <div class="flex space-x-6 md:order-2">
        <a href="https://www.facebook.com/casadege.com.br" target="_blank"><img class="w-6 opacity-40 hover:opacity-20" src="/assets/images/redes_sociais/facebook.webp" alt="Facebook" /></a>
        <a href="https://instagram.com/casadege" target="_blank"><img class="w-6 opacity-40 hover:opacity-20" src="/assets/images/redes_sociais/instagram.webp" alt="Instagram" /></a>
        <a href="https://youtube.com.br/channel/UC9sKpdku8Hy7t2Cru0vuaMw" target="_blank"><img class="w-6 opacity-40 hover:opacity-20" src="/assets/images/redes_sociais/youtube.webp" alt="Youtube" /></a>
      </div>
      <p class="mt-8 text-sm text-gray-500 md:order-1 md:mt-0">
        &copy; 26.756.989/0001-51 DBS COMERCIAL DE PRODUTOS DE LIMPEZA LTDA<br />
        Rua Rosa Mafei, nº 445 - Bonsucesso | Guarulhos - SP CEP 07177-110 | (11) 96629-4417
      </p>
    </div>
  </div>
</footer>
} @placeholder{
<p-skeleton width="100%" height="60rem"></p-skeleton>
}
<!-- Menu Principal -->
<!-- <div class="absolute sm:hidden z-20">
  <div class="w-full bg-white p-4 fixed bottom-0 shadow-[0_5px_5px_5px_rgba(0,0,0,0.3)]">
    <div class="flex justify-center items-center space-x-6 mb-2">
      <div>
        <button title="Minha Conta" [routerLink]="['/a']" class="bg-pink-800 rounded-full p-2 shadow-inner">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-amber-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
          </svg>
        </button>
      </div>

      <div>
        <button title="Página Inicial" [routerLink]="['/']" class="bg-amber-400 rounded-full p-3 shadow-inner">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
          </svg>
        </button>
      </div>

      <div>
        <ng-container *ngIf="cart?.client_marketplace_cart_uid">
          <ng-container *ngIf="cart?.counter_item > 0">
            <div class="bg-red-600 text-xs text-white font-semibold absolute rounded-full px-2 py-1 z-10 ml-8">{{cart.counter_item}}</div>
          </ng-container>
          <button title="Carrinho" [routerLink]="['/p/cart/'+cart.client_marketplace_cart_uid]" class="z-0 relative bg-pink-800 rounded-full p-2 shadow-inner">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-amber-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div> -->
