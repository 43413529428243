declare let gtag: Function;
declare let fbq: Function;
declare global {
  interface Window {
    VLibras: any;
  }
}
import { environment } from 'src/environments/environment';
import { Component, ViewChild, ElementRef, HostListener, ComponentFactoryResolver, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
// [[Providers]]
import { CustomerUserService } from 'src/project/services/panel/client/marketplace/customer/user/user.service';
import { CollectiveMarketplaceService } from '~services/collective/client/marketplace/marketplace.service';
import { ClientMarketplaceCoreCategoryService } from '~services/collective/client/marketplace/core/category/category.service';
import { CollectiveMarketplaceCartService } from '~services/collective/client/marketplace/cart/cart.service';
import { CollectiveMarketplaceCoreVisualBannerService } from '~services/collective/client/marketplace/core/visual/banner/banner.service';
import { ClientMarketplaceCoreProductService } from '~services/collective/client/marketplace/core/product/product.service';
// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: 'app-panel-header',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})
export class WwwHeaderComponent {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any;
  marketplace: any = null;
  cart: any = null;
  banners: any = null;
  banners_fnc_cta: any = null;
  subscribedMethods: any = [];
  fullBannerTopFootBanners: [];

  favicon: HTMLLinkElement = document.querySelector('#app_icon');

  actualUrl: any;
  openMenuMobile: any = false;
  openSubMenuMobile: any = false;

  private renderer: Renderer2;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private router: Router,
    private rendererFactory: RendererFactory2,
    private activatedRoute: ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    private elementRef: ElementRef,
    /**
     * Base de uso em Component
     */
    private userService: CustomerUserService,
    private collectiveMarketplaceService: CollectiveMarketplaceService,
    private collectiveMarketplaceCartService: CollectiveMarketplaceCartService,
    private collectiveMarketplaceCoreVisualBannerService: CollectiveMarketplaceCoreVisualBannerService,
    private clientMarketplaceCoreProductService: ClientMarketplaceCoreProductService,
    /**
     * Services
     */
    private clientMarketplaceCoreCategoryService: ClientMarketplaceCoreCategoryService
  ) {
    /**
     * Profile
     *
     * Request inicial do PROFILE para acompanhamento nas Páginas.
     */
    this.userService.profile().subscribe((Profile: any) => {
      if (Profile != null) {
        this.userService.setUser(Profile.data);
      }
    });
    // ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;

    /**
     * Marketplace
     *
     * Request inicial do MARKETPLACE para acompanhamento nas Páginas.
     */
    this.collectiveMarketplaceService.marketplace().subscribe((Marketplace: any) => {
      if (Marketplace != null) {
        this.collectiveMarketplaceService.setMarketplace(Marketplace.data);
      }
    });
    // ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;

    /**
     * Cart
     *
     * Request inicial do CARRINHO para acompanhamento nas Páginas.
     */
    this.collectiveMarketplaceCartService.cart({}).subscribe((Cart: any) => {
      if (Cart != null) {
        this.collectiveMarketplaceCartService.setCart(Cart.data);
      }
    });
    // ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;

    /**
     * Banners
     *
     * Request inicial do BANNER para acompanhamento nas Páginas.
     */
    this.collectiveMarketplaceCoreVisualBannerService.index({ per_page: 100, core_visual_banner_status: 'active' }).subscribe((Banner: any) => {
      if (Banner != null) {
        this.collectiveMarketplaceCoreVisualBannerService.setBanners(Banner.data.data);
      }
    });
    // ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;

    {
      if (localStorage.getItem('boxPriv') != null) {
        this.boxPriv = false;
      }

      this.renderer = rendererFactory.createRenderer(null, null);

      this.clientMarketplaceCoreCategoryServiceIndexMajor();
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.actualUrl = this.router.url;
  }

  /**
   * on init
   */
  ngOnInit() {
    this.ipBuildEcommerceDependencies();
    this.clientMarketplaceCoreCategoryServiceIndexMajor();
  }

  /**
   * build ecommerce dependencies
   */
  ipBuildEcommerceDependencies() {
    /**
     * Base
     *
     * Request da base de uso.
     */
    // PROFILE
    this.userService.getUser().subscribe((Profile: any) => {
      if (Profile != null) {
        if (JSON.parse(Profile) != null) {
          this.profile = JSON.parse(Profile);
        }
      }
    });
    // MARKETPLACE
    this.collectiveMarketplaceService.getMarketplace().subscribe((Marketplace: any) => {
      if (Marketplace != null) {
        if (JSON.parse(Marketplace) != null) {
          this.marketplace = JSON.parse(Marketplace);
          /**
           *
           * CONFIGURAÇÕES ADICIONAIS DESTE `COMPONENTE`
           *
           */
          this.tagAccessibility();
          this.favicon.href = '/assets/themes/' + environment.marketplace.domainObject.sld + '/images/favicon.png';
        }
      }
    });
    // CART
    this.collectiveMarketplaceCartService.getCart().subscribe((Cart: any) => {
      if (Cart != null) {
        if (JSON.parse(Cart) != null) {
          this.cart = JSON.parse(Cart);
        }
      }
    });
    // BANNERS
    this.banners_fnc_cta = function (banner: any) {
      if (banner.url != '') {
        document.location = banner.url;
      }
      return false;
    };
    this.collectiveMarketplaceCoreVisualBannerService.getBanners().subscribe((Data: any) => {
      if (Data != null) {
        if (JSON.parse(Data) != null) {
          this.banners = JSON.parse(Data);
          let bannersCopy = this.banners.slice();
          this.fullBannerTopFootBanners = bannersCopy.filter((banner) => banner.core_visual_banner_area === 'full_banner_top_foot');
        }
      }
    });

    // # /*
    // # |--------------------------------------------------------------------------
    // # | Subscribers: Search
    // # |--------------------------------------------------------------------------
    // # |
    // # */
    var _ = this.clientMarketplaceCoreProductService.getSearch().subscribe((searchReplaySubject: any) => {
      this.search.query = searchReplaySubject.query;
      this.clientMarketplaceCoreProductService.search(searchReplaySubject).subscribe((searchResults: any) => {
        this.clientMarketplaceCoreProductService.setSearchResult(searchResults);
      });
    });
    this.subscribedMethods.push(_);

    var _ = this.clientMarketplaceCoreProductService.getSearchResult().subscribe((searchResults: any) => {
      this.searchResults = searchResults;
    });
    this.subscribedMethods.push(_);
  }

  ngOnDestroy() {
    this.subscribedMethods.forEach((subscriber: any, key: number) => {
      // console.log(this.subscribedMethods[key]);
      subscriber.unsubscribe();
    });
  }

  /**
   * after view init
   */
  ngAfterViewInit() {
    this.headerMenuFixedOnTop();
  }

  boxPriv: any = true;
  /**
   * close privacy
   */
  fnClosePrivacy() {
    localStorage.setItem('boxPriv', 'no');
    this.boxPriv = false;
  }

  categories: any;
  clientMarketplaceCoreCategoryServiceIndexMajor() {
    var _ = this.clientMarketplaceCoreCategoryService.index_major({ per_page: 100 });
    if (_ !== null) {
      _.subscribe(
        (response: any) => {
          this.categories = response.data.data;
        },
        (error) => {}
      );
    }
  }

  categoryChildren: any;
  currentCategory: any;
  showCategoryChildren: boolean = false;

  getChildren(children: any, category: any) {
    this.categoryChildren = children;
    this.currentCategory = category;
    this.showCategoryChildren = true;
  }

  hideChildren() {
    this.showCategoryChildren = false;
  }

  /**
   * Collectives customer user service logout
   * @param login
   */
  collectiveCustomerUserServiceLogout(login: any) {
    localStorage.clear();

    let _ = this.userService.logout(login);
    if (_ !== null) {
      _.subscribe(
        () => {
          document.location.href = '/';
        },
        () => {
          document.location.href = '/';
        }
      );
    }
  }

  accessibility: any = false;

  /**
   * Tags accessibility
   */
  tagAccessibility() {
    // ADICIONA: SRC PARA SCRIPT
    let s = document.createElement('script');
    s.defer = true;
    s.src = '//vlibras.gov.br/app/vlibras-plugin.js';
    s.onload = function () {
      let VLibras = new window.VLibras.Widget('//vlibras.gov.br/app');
    };
    this.renderer.appendChild(document.body, s);
  }

  // /**
  //  * Headers menu fixed on top
  //  */
  headerMenuFixedOnTop() {
    if (!this.actualUrl.includes('/social')) {
      var desktop_element = 'desktop_bar';
      var mobile_element = 'mobile_bar';
      var gum_index = ['fixed', 'w-full', 'top-0', 'drop-shadow-md'];

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
        var mobileElement = document.getElementById(mobile_element);
        if (mobileElement) {
          var gum = mobileElement.offsetTop;
          window.addEventListener('scroll', function () {
            var obj = document.getElementById(mobile_element);
            if (obj) {
              var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

              // Fixa Obj no Topo.
              if (y > obj.offsetTop && y > gum) {
                if (!obj.classList.contains(gum_index[0])) {
                  obj.classList.add(...gum_index);
                  gum = y;
                }
              } else {
                if (obj.classList.contains(gum_index[0])) {
                  obj.classList.remove(...gum_index);
                  gum = y;
                }
              }
            }
          });
        }
      } else {
        var desktopElement = document.getElementById(desktop_element);
        if (desktopElement) {
          var gum = desktopElement.offsetTop;
          window.addEventListener('scroll', function () {
            var obj = document.getElementById(desktop_element);
            if (obj) {
              var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

              // Fixa Obj no Topo.
              if (y > obj.offsetTop && y > gum) {
                if (!obj.classList.contains(gum_index[0])) {
                  obj.classList.add(...gum_index);
                  gum = y;
                }
              } else {
                if (obj.classList.contains(gum_index[0])) {
                  obj.classList.remove(...gum_index);
                  gum = y;
                }
              }
            }
          });
        }
      }
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.actualUrl.includes('/social')) {
      const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      // Defina o valor limite que você deseja usar para ocultar o logo
      const limiteRolagem = 100;

      // Verifique se a rolagem é maior que o limite
      if (verticalOffset > limiteRolagem) {
        // Adicione uma classe CSS para ocultar o logo
        this.renderer.addClass(this.elementRef.nativeElement.querySelector('#logo'), 'hidden');
      } else {
        // Remova a classe CSS para exibir o logo
        this.renderer.removeClass(this.elementRef.nativeElement.querySelector('#logo'), 'hidden');
      }
    }
  }

  // # /*
  // # |--------------------------------------------------------------------------
  // # | Search
  // # |--------------------------------------------------------------------------
  // # |
  // # */
  search: any = {
    per_page: 12,
    page: 1,
    client_marketplace_id: 1,
    query: '',
    start_at: new Date(),
  };
  searchResults: any;
  clientMarketplaceCoreProductServiceIndex() {
    this.clientMarketplaceCoreProductService.setSearch(this.search);
  }

  clientMarketplaceCoreProductServiceClear() {
    this.search.query = '';
    this.clientMarketplaceCoreProductServiceIndex();
  }

  clientMarketplaceCoreProductServiceRedirectSearch() {
    if (this.search.query != '' && typeof this.search.query !== 'undefined') {
      this.router.navigate(['/s/query/' + this.search.query.replace(/\s+/g, '-')]);
    } else {
      this.router.navigate(['/s']);
    }
  }

  hideMenu: boolean = true;

  onHideMobileMenu() {
    this.hideMenu = !this.hideMenu;
  }

  cleaning: boolean = false;
  kits: boolean = false;
  laundry: boolean = false;

  onShowCategoryChildren(data: any) {
    this[data] = !this[data];
  }
}
